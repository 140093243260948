module.exports = [
  "Fantom of the Opera (FTM-USDC)",
  "Phantom Dai Opera (FTM-DAI)",
  "Grand Orchestra (FTM-ETH-BTC)",
  "Late Quartet (FTM-USDC-ETH-BTC)",
  "Steady Beets, Act II (USDC-DAI)",
  "Steady Beets, Yearn Boosted ",
  "Guqin Qi (MAI-DAI-USDC)",
  "Ziggy Stardust (MIM-USDC-USDT)",
  "Grand Steady Orchest (TUSD-USDC)",
  "Double Dollar (USDC-UST-MIM)",
  "Variation of USDC (USDC-asUSDC)",
  "The E Major (ETH-USDC)",
  "The B Major (BTC-USDC)",
  "The Classic Trio (BTC-ETH-USDC)",
  "Battle of the Bands (L1 tokens)",
  "Fantom Conservatory of Music ",
  "Tubular Bells: Curved and Linked",
  "Baron von Binance ",
  "The Solana Sonata",
  "Demeter's Degree (gOHM-FTM)",
  "When two become one Hundred",
  "Beardhemian Brushsody",
  "The Sound Of Moosic",
  "A Song of Ice and Fire",
  "Coral Symphony (BELUGA-FTM)",
  "Dante Symphony (WSTA-BEETS-etc)",
  "Pirate Party (LQDR-FTM)",
  "The Monolith (EXOD-gOHM-etc)",
  "Lonely Mountain (RING-USDC)",
  "Hall of Mountain King (SUMMIT)",
  "Exploding Shrapnell (SHRAP-FTM)",
  "Statera Maxi Duet (wSTA-BEETS)",
  "Spooky Melodies (BOO-USDC)",
  "Ghost Reveries (Tarot-FTM)",
  "Melody from Hell (IMP-FTM-etc)",
  "Stable Credit Sonata (CREDIT-etc",
  "Sigma's Wrapped Symphony (wsSCR)",
  "Beet Mason's Oath (OATH-BEETS)",
  "A Beefy Tale (FTM-beFTM)",
  "Boomer BEETS (WBTC-BEETS)",
  "From Gods (DEI-bb-yv-USD)",
  "Never PANIC (PANIC-bb-yv-FTM)",
  "Treeb Me Bro (TREEB-USDC)",
  "Pirate in C (LQDR-cLQDR)",
  "$FANG Never Too Old to Yearn",
  "Water Music (SPIRIT-LINSPIRIT)",
  "Valhalla Calling (HND-liHND)",
  "Bee-Thoven and BEARS (COMB-USDC)",
  "Two Gods One Pool (DEUS-DEI)",
  "Dai-Abolical Balance (USDB-DAI)",
  "Desert Sonata (SCARAB-FTM)",
  "CRE8R in F-Major (CRE8R-FTM)",
  "Mor Steady Beets (MOR-bb-yv-USD)",
  "Weird Songs (BTC-ETH-LINK-etc)",
  "Gelato Genesis (GEL-USDC)",
  "Clair de Lune (LUNA-FTM-UST)",
  "Twisted $ (UST-FRAX-USDC-USDT)"
]