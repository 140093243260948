module.exports = {
  "data": {
    "votes": [
      {
        "choice": {
          "26": 5,
          "27": 5,
          "29": 5,
          "32": 20,
          "33": 5,
          "38": 5,
          "52": 20
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 1,
          "32": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "32": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 6,
          "29": 94
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "30": 35,
          "32": 35,
          "33": 30
        }
      },
      {
        "choice": {
          "33": 2236
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "51": 33,
          "52": 67
        }
      },
      {
        "choice": {
          "41": 8800
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 3,
          "52": 97
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "32": 1,
          "33": 1,
          "41": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 3
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 1,
          "44": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "29": 21,
          "32": 30,
          "41": 23,
          "44": 5,
          "51": 21
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "30": 50,
          "32": 50
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 500,
          "41": 3700
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 437,
          "44": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 100
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "41": 8,
          "51": 2,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "30": 1,
          "39": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "30": 1,
          "39": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "41": 1
        }
      },
      {
        "choice": {
          "32": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "32": 9
        }
      },
      {
        "choice": {
          "51": 20,
          "52": 80
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "32": 100
        }
      },
      {
        "choice": {
          "32": 100
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "29": 17
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "41": 2200
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "32": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 40
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "26": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "29": 1,
          "32": 1,
          "33": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "32": 100
        }
      },
      {
        "choice": {
          "27": 6,
          "41": 2,
          "44": 2
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "32": 2,
          "33": 2
        }
      },
      {
        "choice": {
          "26": 1,
          "29": 1,
          "38": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 3,
          "29": 1,
          "30": 3,
          "32": 3,
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "32": 2,
          "33": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "32": 1,
          "41": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "33": 10
        }
      },
      {
        "choice": {
          "32": 2,
          "49": 2
        }
      },
      {
        "choice": {
          "26": 3,
          "29": 1,
          "30": 3,
          "32": 3,
          "33": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "29": 50,
          "32": 50
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "26": 5,
          "29": 2,
          "30": 5,
          "32": 5,
          "33": 2
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 10,
          "29": 90
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 5
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "41": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 7,
          "33": 4
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 4
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 172,
          "41": 171
        }
      },
      {
        "choice": {
          "27": 1,
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "51": 239,
          "52": 451
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "51": 28,
          "52": 72
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "29": 1,
          "32": 1,
          "33": 1,
          "41": 1,
          "46": 1,
          "49": 1,
          "50": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "29": 30,
          "51": 20,
          "52": 50
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 30,
          "52": 70
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "29": 10,
          "41": 30,
          "44": 5,
          "49": 30,
          "52": 25
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "41": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 7
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 1135
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "49": 3
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 3,
          "52": 13
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "25": 1,
          "41": 8,
          "51": 6
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "45": 1,
          "57": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 30,
          "41": 70
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "32": 4,
          "38": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "41": 1,
          "44": 3,
          "49": 2,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 705
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 5792
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 18
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "26": 30,
          "27": 10,
          "51": 30,
          "52": 30
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 10,
          "52": 10
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "51": 74
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "32": 2,
          "33": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 10,
          "29": 40,
          "41": 100,
          "49": 60,
          "51": 60,
          "52": 60
        }
      },
      {
        "choice": {
          "27": 75,
          "33": 25
        }
      },
      {
        "choice": {
          "52": 51
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 1,
          "39": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "27": 1,
          "33": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "30": 342
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 303
        }
      },
      {
        "choice": {
          "27": 1,
          "41": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 90,
          "49": 10
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "32": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 4,
          "52": 6
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 2,
          "51": 1,
          "52": 2
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "33": 1,
          "41": 1,
          "45": 1,
          "49": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "49": 5
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "30": 1,
          "32": 1,
          "38": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 17
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 3
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "30": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 33,
          "41": 34,
          "49": 33
        }
      },
      {
        "choice": {
          "26": 2,
          "30": 1,
          "32": 2,
          "33": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "49": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "44": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 2,
          "41": 4,
          "52": 4
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "32": 2
        }
      },
      {
        "choice": {
          "41": 1,
          "49": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "35": 100
        }
      },
      {
        "choice": {
          "52": 13
        }
      },
      {
        "choice": {
          "52": 50
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "41": 1,
          "49": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "27": 10
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "41": 424
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "41": 49
        }
      },
      {
        "choice": {
          "32": 17000
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 874
        }
      },
      {
        "choice": {
          "57": 1
        }
      },
      {
        "choice": {
          "52": 2550
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "27": 15,
          "29": 20,
          "32": 15,
          "33": 25,
          "51": 25
        }
      },
      {
        "choice": {
          "27": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "4": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "41": 100
        }
      },
      {
        "choice": {
          "51": 6
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "30": 100
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "51": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "30": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "26": 6
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "49": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "49": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 18,
          "27": 1,
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 50,
          "52": 50
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "39": 1,
          "49": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "49": 4
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "32": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1,
          "44": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "4": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 1,
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 21345
        }
      },
      {
        "choice": {
          "4": 100
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "49": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "33": 8
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 22,
          "52": 144
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "33": 1,
          "45": 1,
          "49": 4,
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "8": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 2700
        }
      },
      {
        "choice": {
          "52": 11
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 3
        }
      },
      {
        "choice": {
          "30": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1900
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "15": 1,
          "19": 1,
          "20": 2,
          "26": 2
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "39": 400
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "41": 2
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "29": 1,
          "31": 1,
          "33": 1,
          "46": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "41": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 9
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "29": 1,
          "30": 1,
          "33": 1,
          "44": 1,
          "49": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "2": 1
        }
      },
      {
        "choice": {
          "27": 3,
          "52": 7
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 851
        }
      },
      {
        "choice": {
          "27": 1,
          "49": 19
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 4000,
          "29": 2000,
          "33": 4000,
          "44": 2000
        }
      },
      {
        "choice": {
          "33": 1,
          "39": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 3,
          "49": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 3,
          "27": 1,
          "30": 3,
          "33": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "51": 400,
          "52": 170
        }
      },
      {
        "choice": {
          "26": 5
        }
      },
      {
        "choice": {
          "27": 30,
          "52": 70
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 2,
          "39": 1,
          "52": 17
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "16": 1,
          "27": 1,
          "38": 2
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 2272
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 2,
          "29": 1,
          "49": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "26": 25,
          "27": 25,
          "29": 12.5,
          "30": 12.5,
          "33": 12.5,
          "52": 12.5
        }
      },
      {
        "choice": {
          "45": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "51": 7000
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "34": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "49": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "49": 269
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 3
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "38": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "50": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "33": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1042
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "25": 1,
          "26": 1,
          "27": 1,
          "28": 1,
          "30": 1,
          "32": 1,
          "33": 1,
          "35": 1,
          "36": 1,
          "38": 1,
          "39": 1,
          "41": 1,
          "44": 1,
          "45": 1,
          "49": 1,
          "51": 1,
          "52": 1,
          "53": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "52": 197
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "2": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "46": 50,
          "51": 50
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 11,
          "52": 7
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "50": 40
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "32": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "33": 2
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 10,
          "33": 20,
          "49": 25,
          "52": 45
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 434,
          "33": 300,
          "49": 300
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1
        }
      },
      {
        "choice": {
          "49": 100
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 100,
          "38": 27
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "33": 271
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "33": 100
        }
      },
      {
        "choice": {
          "38": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "25": 1
        }
      },
      {
        "choice": {
          "25": 9206,
          "31": 617,
          "45": 161,
          "49": 15,
          "56": 1
        }
      },
      {
        "choice": {
          "26": 88,
          "27": 12
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1,
          "30": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "33": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "6": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 10,
          "44": 10,
          "49": 10
        }
      },
      {
        "choice": {
          "15": 25,
          "49": 50,
          "52": 25
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "29": 8,
          "52": 29
        }
      },
      {
        "choice": {
          "26": 2700
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 324.9883
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 6,
          "41": 9
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 34,
          "44": 66
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "52": 3900
        }
      },
      {
        "choice": {
          "26": 9,
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "29": 2
        }
      },
      {
        "choice": {
          "53": 1
        }
      },
      {
        "choice": {
          "52": 4
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1000
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "45": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 234.5
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 4,
          "52": 6
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 3
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 246
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "29": 34,
          "51": 33,
          "52": 33
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "30": 7
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 50,
          "39": 50
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1000
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "50": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "52": 6
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 50,
          "52": 50
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "27": 2
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 50,
          "44": 50
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "29": 4
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "39": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 10
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 12,
          "51": 61
        }
      },
      {
        "choice": {
          "1": 1,
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "51": 210
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "49": 476
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "43": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "30": 2
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 322
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 144
        }
      },
      {
        "choice": {
          "26": 399
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "41": 2
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 2
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1,
          "52": 4
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 200
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 8,
          "52": 92
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 4500
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 50,
          "44": 50
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1000
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 2,
          "28": 1,
          "29": 1,
          "38": 3
        }
      },
      {
        "choice": {
          "27": 2,
          "28": 1,
          "29": 1,
          "38": 3
        }
      },
      {
        "choice": {
          "27": 2,
          "28": 1,
          "29": 1,
          "38": 3
        }
      },
      {
        "choice": {
          "52": 46
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 5
        }
      },
      {
        "choice": {
          "44": 2
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "4": 1,
          "5": 1,
          "56": 1,
          "57": 1
        }
      },
      {
        "choice": {
          "44": 2,
          "51": 3
        }
      },
      {
        "choice": {
          "26": 2
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 4
        }
      },
      {
        "choice": {
          "29": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1447
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 94
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1235
        }
      },
      {
        "choice": {
          "52": 35691161
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "38": 2,
          "45": 1
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "39": 25,
          "44": 50,
          "52": 25
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 25,
          "51": 25,
          "52": 50
        }
      },
      {
        "choice": {
          "52": 1690.5374
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 7
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "46": 200
        }
      },
      {
        "choice": {
          "49": 21
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "16": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "45": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 8,
          "44": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "50": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 3,
          "56": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 4
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "45": 1,
          "48": 1,
          "49": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "26": 416
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "45": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "45": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "51": 7900
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 3500
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "52": 7
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 24
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 140
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 900
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "48": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 4,
          "38": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "45": 50
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "4": 1,
          "14": 1,
          "26": 1,
          "27": 1,
          "38": 1,
          "39": 1,
          "44": 1,
          "45": 1,
          "51": 1,
          "52": 1,
          "53": 1
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "50": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 3768
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "2": 1,
          "26": 1,
          "27": 1,
          "29": 1,
          "38": 1,
          "39": 1,
          "44": 1,
          "45": 1,
          "51": 1,
          "52": 1,
          "53": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "38": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "45": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 7
        }
      },
      {
        "choice": {
          "27": 6000
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 360
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "50": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "15": 1,
          "27": 1,
          "56": 1
        }
      },
      {
        "choice": {
          "1": 38
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "45": 4
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "44": 25,
          "49": 50,
          "52": 25
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "50": 1
        }
      },
      {
        "choice": {
          "45": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "45": 100
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "52": 21
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 2
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "15": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "45": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "37": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 99,
          "53": 11
        }
      },
      {
        "choice": {
          "27": 102
        }
      },
      {
        "choice": {
          "52": 177
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "45": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1908
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "51": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "45": 100
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "45": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "37": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "5": 20
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 2
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "1": 276
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "4": 1,
          "27": 1,
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "45": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "36": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "15": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "38": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "44": 28
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "2": 1,
          "3": 1,
          "5": 1,
          "6": 1,
          "7": 1,
          "8": 1,
          "11": 1,
          "12": 1,
          "16": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "1": 1,
          "4": 1,
          "56": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 9
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 50,
          "49": 50
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "55": 1
        }
      },
      {
        "choice": {
          "52": 37000
        }
      },
      {
        "choice": {
          "45": 373
        }
      },
      {
        "choice": {
          "27": 22
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "49": 10000
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1340
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "2": 1,
          "25": 1,
          "27": 1,
          "29": 1,
          "30": 1,
          "31": 1,
          "33": 1,
          "39": 1,
          "43": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 2
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 50,
          "38": 50
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "27": 35
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 55,
          "52": 45
        }
      },
      {
        "choice": {
          "48": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 200000
        }
      },
      {
        "choice": {
          "39": 75
        }
      },
      {
        "choice": {
          "27": 20
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "44": 50,
          "49": 50
        }
      },
      {
        "choice": {
          "27": 3,
          "38": 1,
          "56": 3
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 7555
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 3,
          "52": 6
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "53": 1
        }
      },
      {
        "choice": {
          "29": 117
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 50,
          "55": 50
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 3,
          "51": 4,
          "52": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "20": 200,
          "38": 274
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 50,
          "52": 50
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "15": 1,
          "27": 2,
          "39": 2,
          "44": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 103
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "56": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 163
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "29": 2,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "33": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 11
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "38": 1,
          "48": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 50,
          "44": 50
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "25": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 2
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "46": 66
        }
      },
      {
        "choice": {
          "55": 1
        }
      },
      {
        "choice": {
          "1": 80,
          "29": 20
        }
      },
      {
        "choice": {
          "38": 14
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 50000
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 17000
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "39": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "53": 5
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1000
        }
      },
      {
        "choice": {
          "6": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1,
          "53": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 5800
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "27": 99
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 3,
          "49": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 50
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 10000
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 667,
          "52": 5
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "44": 8
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "51": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "39": 2
        }
      },
      {
        "choice": {
          "33": 10
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 291
        }
      },
      {
        "choice": {
          "26": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 108.27208
        }
      },
      {
        "choice": {
          "27": 40,
          "38": 40,
          "44": 20
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 100
        }
      },
      {
        "choice": {
          "53": 100
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "29": 2,
          "52": 4
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "39": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 100
        }
      },
      {
        "choice": {
          "34": 10000
        }
      },
      {
        "choice": {
          "29": 2,
          "49": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "44": 2,
          "49": 2
        }
      },
      {
        "choice": {
          "38": 100
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "27": 4,
          "39": 2,
          "52": 2
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "37": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 50,
          "44": 50
        }
      },
      {
        "choice": {
          "37": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "36": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "29": 2,
          "31": 1,
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "39": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "48": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 4,
          "44": 12
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "10": 1
        }
      },
      {
        "choice": {
          "52": 915
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "44": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 9
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 40,
          "52": 60
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "49": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "42": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "37": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 734
        }
      },
      {
        "choice": {
          "51": 10
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 36
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 11400
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 144
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "38": 10
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "39": 7
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "39": 1
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "27": 50,
          "49": 50
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 8000
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 50,
          "52": 50
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100000000000
        }
      },
      {
        "choice": {
          "27": 16
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "52": 6,
          "56": 3
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "56": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "29": 1,
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "36": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "1": 1
        }
      },
      {
        "choice": {
          "45": 1
        }
      },
      {
        "choice": {
          "27": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "4": 1,
          "8": 1,
          "10": 1,
          "56": 1,
          "57": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "57": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "56": 100000000000000
        }
      },
      {
        "choice": {
          "36": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1400
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "51": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 231
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 2,
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "2": 1,
          "3": 1,
          "4": 1,
          "5": 1,
          "6": 1,
          "7": 1,
          "8": 1,
          "9": 1,
          "10": 1,
          "11": 1,
          "12": 1,
          "13": 1,
          "14": 1,
          "15": 1,
          "16": 1,
          "17": 1,
          "18": 1,
          "19": 1,
          "20": 1,
          "21": 1,
          "22": 1,
          "23": 1,
          "24": 1,
          "25": 1,
          "26": 1,
          "27": 1,
          "28": 1,
          "29": 1,
          "30": 1,
          "31": 1,
          "32": 1,
          "33": 1,
          "34": 1,
          "35": 1,
          "36": 1,
          "37": 1,
          "38": 1,
          "39": 1,
          "40": 1,
          "41": 1,
          "42": 1,
          "43": 1,
          "44": 1,
          "45": 1,
          "46": 1,
          "47": 1,
          "48": 1,
          "49": 1,
          "50": 1,
          "51": 1,
          "52": 1,
          "53": 1,
          "54": 1,
          "55": 1,
          "56": 1,
          "57": 1
        }
      },
      {
        "choice": {
          "29": 1500
        }
      },
      {
        "choice": {
          "52": 658
        }
      },
      {
        "choice": {
          "29": 40,
          "31": 20,
          "52": 40
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "5": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 608
        }
      },
      {
        "choice": {
          "27": 10
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 8,
          "2": 3,
          "3": 3,
          "4": 3,
          "5": 3,
          "6": 3,
          "7": 3,
          "8": 3,
          "9": 3,
          "10": 3,
          "11": 3,
          "12": 3,
          "13": 3,
          "14": 3,
          "15": 3,
          "16": 3,
          "17": 3,
          "18": 3,
          "19": 3,
          "20": 3,
          "21": 3,
          "22": 3,
          "23": 3,
          "24": 3,
          "25": 3,
          "26": 3,
          "27": 5,
          "28": 3,
          "29": 5,
          "30": 3,
          "31": 3,
          "32": 3,
          "33": 3,
          "34": 3,
          "35": 3,
          "36": 3,
          "37": 3,
          "38": 3,
          "39": 3,
          "40": 3,
          "41": 3,
          "42": 3,
          "43": 3,
          "44": 5,
          "45": 3,
          "46": 3,
          "47": 3,
          "48": 3,
          "49": 3,
          "50": 3,
          "51": 3,
          "52": 5,
          "53": 3,
          "54": 3,
          "55": 3,
          "56": 3,
          "57": 5
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "4": 30,
          "39": 70
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 5
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "4": 1
        }
      },
      {
        "choice": {
          "53": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "31": 1,
          "46": 1
        }
      },
      {
        "choice": {
          "45": 5400
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "5": 1
        }
      },
      {
        "choice": {
          "27": 601
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "27": 7,
          "52": 3
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "1": 58,
          "27": 51
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "15": 1,
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 25,
          "49": 25,
          "52": 50
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 8,
          "52": 2
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "38": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 3,
          "29": 3
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 2
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "27": 1
        }
      },
      {
        "choice": {
          "27": 4
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "46": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "34": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "1": 25,
          "27": 50,
          "38": 25
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "44": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1,
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 716
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 7689,
          "31": 30,
          "42": 4637
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "1": 2,
          "3": 1,
          "4": 1,
          "27": 1
        }
      },
      {
        "choice": {
          "4": 1
        }
      },
      {
        "choice": {
          "4": 1,
          "29": 1,
          "31": 1,
          "46": 1
        }
      },
      {
        "choice": {
          "4": 1,
          "29": 1,
          "31": 1,
          "46": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "24": 1,
          "29": 1,
          "56": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "2": 1,
          "3": 1,
          "4": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "50": 2
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "52": 1
        }
      },
      {
        "choice": {
          "52": 100
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "54": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "5": 1,
          "27": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "1": 10,
          "3": 5,
          "5": 3,
          "46": 21
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "26": 1,
          "29": 2,
          "31": 2
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1,
          "31": 1,
          "39": 1,
          "46": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "32": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 589
        }
      },
      {
        "choice": {
          "50": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "31": 100
        }
      },
      {
        "choice": {
          "27": 1,
          "29": 1
        }
      },
      {
        "choice": {
          "31": 1,
          "46": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "33": 1
        }
      },
      {
        "choice": {
          "29": 2
        }
      },
      {
        "choice": {
          "46": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 4000,
          "44": 10000000
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "49": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "38": 100
        }
      },
      {
        "choice": {
          "46": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "1": 17,
          "27": 13,
          "29": 3
        }
      },
      {
        "choice": {
          "8": 1,
          "15": 1
        }
      },
      {
        "choice": {
          "29": 100
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "38": 57
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1,
          "41": 1,
          "44": 1,
          "49": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "25": 1
        }
      },
      {
        "choice": {
          "28": 1
        }
      },
      {
        "choice": {
          "29": 30,
          "49": 70
        }
      },
      {
        "choice": {
          "27": 100
        }
      },
      {
        "choice": {
          "4": 50,
          "27": 25,
          "29": 25
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "1": 1,
          "44": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "29": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      },
      {
        "choice": {
          "30": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "26": 1
        }
      },
      {
        "choice": {
          "27": 1
        }
      }
    ]
  }
}